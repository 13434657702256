import React from "react"
import { ModalRoutingContext } from "gatsby-plugin-modal-routing"

import { LayoutFull } from "./LayoutFull"
import { LayoutModal } from "./LayoutModal"

export const Layout = ({ children, navigation, noWaveHeader, noWaveFooter}) => {

  return (
    <ModalRoutingContext.Consumer>
        {({ modal, closeTo }) =>
          modal ? (
            <LayoutModal closeTo={closeTo} navigation={navigation}>
              {children}
            </LayoutModal>
          ) : (
            <>                
            <LayoutFull noWaveHeader={noWaveHeader} noWaveFooter={noWaveFooter}>{children}</LayoutFull>
            </>
          )
        }     
    </ModalRoutingContext.Consumer>
  )
}
