import React from "react"
import { graphql, Link } from "gatsby"
import { SiteMetadata, ProductsCarousel } from "../../components"
import { WavesSVG, ZigZag, MedSVG } from '../../components/SVG/'
import { Layout } from "../../layouts/Layout"
import Img from "gatsby-image"

import {Container, Row, Col} from "react-bootstrap"

export default ({ data }) => {
  return (
    <Layout>
        <SiteMetadata 
            title="Натуральный мед с пасеки" 
            canonical="/shop/med/"
            description="Мы занимаемся пчеловодством более 30 лет, поэтому у нас всегда можно купить домашний натуральный мёда с пасеки. Крем-мед, мед с орехами, разнотравье и не только." 
        />
        <link
            rel="stylesheet"
            type="text/css"
            charSet="UTF-8"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        <Container>
            <Row>
              <Col>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center"  itemScope itemType="http://schema.org/BreadcrumbList">
                  <li className="breadcrumb-item"  itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                    <Link to="/" itemProp="item">
                        <span itemProp="name">Главная</span>
                    </Link>
                    <meta itemProp="position" content="1"/>
                  </li>
                  <li className="breadcrumb-item" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                    <Link to="/shop/" itemProp="item">
                        <span itemProp="name">Продукция</span>
                    </Link>
                    <meta itemProp="position" content="2"/>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Мед</li>
                </ol>
              </nav>              
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={8} lg={6} className="text-center offset-md-2 offset-lg-3 mt-5">
                <h1 className="d-inline-block mb-4 beeright-small">
                    Натуральный мёд, подаренный самой природой!
                </h1>
                <p>
                    Мы занимаемся пчеловодством более 30 лет, поэтому уверены, что натуральный мёд с нашей пасеки принесёт большую пользу для организма.
                    Благодаря витаминному составу, а так же наличию пчелиных ферментов, мёд практически полностью усваивается человеком - купите и убедитесь сами!
                </p> 
                <p className="small">
                    Доставка осуществляется по всей территории России.
                </p>               
              </Col>              
            </Row>
            <Row className="my-5">
                <Col className="d-flex align-content-center">
                    <Row className="d-sm-flex flex-md-column services-svg text-center">                        
                        <Col xs={12} sm className="mb-3 pb-5 pb-sm-0">
                            <MedServices svg="sosud" text="Укрепляет сосуды кровеносной системы"/>
                        </Col>  
                        <Col className="mb-3 order-sm-first">
                            <MedServices svg="immunitet" text="Укрепляет иммунитет"/>
                        </Col>                                           
                        <Col className="mb-3">
                            <MedServices svg="pischevarenie" text="Улучшает пищеварение"/>
                        </Col>                          
                    </Row>                
                </Col>
                <Col sm={12} md={6} className="align-content-center justify-content-center my-auto">
                    <Img fluid={data.honeytop.childImageSharp.fluid} alt="натуральный мед с орехами"/>
                </Col>
                <Col className="d-flex align-content-center">
                    <Row className="d-sm-flex flex-md-column services-svg text-center">
                        <Col className="mb-3">
                            <MedServices svg="appetit" text="Улучшает аппетит"/>
                        </Col>
                        <Col className="mb-3 order-sm-last">
                            <MedServices svg="protivogrib" text="Противогрибковый эффект"/>
                        </Col>
                        <Col xs={12} sm className="mb-3 mt-5 mt-sm-0">
                            <MedServices svg="vizdorovlenie" text="Способствует скорейшему выздоровлению простудных заболеваний"/>
                        </Col>
                    </Row>                
                </Col>
            </Row>
        </Container>
        
        <WavesSVG up/>
        <Container fluid className="blue">
            <Container>
                <Row>
                    <Col className="text-center mt-3">
                        <h2>Любовь с первой, второй, третьей ложки!</h2>
                    </Col>
                </Row>
                <ProductsCarousel nodes={data.items.nodes} />
                <Row className="d-flex justify-content-center">
                    <Link className="btn btn-primary btn-lg rounded mx-3 mb-3" to="/shop/">Все товары</Link> 
                </Row>
            </Container>            
        </Container>
        <WavesSVG down/>
        <Container fluid>
            <Row>
                <Col className="text-center text-md-left offset-lg-1 offset-xl-2">
                <h3 className="d-inline-block mb-4 title-container">
                    Сами делаете мед?
                    <ZigZag />
                </h3>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex align-items-center text-center text-md-left offset-lg-1 offset-xl-2">
                    <div>
                        <p>
                            Не сами.<br/>
                            Мед делают наши пчелки. 150 пчелиных семей каждый день с утра до вечера летают между всеми цветами в округе и несут сладкий нектар в улей.
                            Малина, липа, дягиль, донник, душица, кипрей и много других растений!
                        </p>
                        <p>
                            Наша пасека находится в северо-западной части России, а именно - Псковская область. 
                            Место, где поля не сеют и не пашут, что дает горомную пользу для наших пчел.
                            Без пестицидов и инсектицидов, луговое и лесное разнообразие цветущих растений.
                        </p>                        
                    </div>  
                </Col>
                <Col sm={12} md={6} className="position-relative align-content-center justify-content-end pr-0 my-auto">
                    <Img fluid={data.honeycomb.childImageSharp.fluid} alt="соты с медом"/>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center mt-5 mb_5">
                <Col xs={10} sm={8} md={6} lg={4} className="text-center mt-5">
                    <p className="display-4">Доказано, употребляйте по ложечке меда в день - и будет Вам счастье!)</p>
                </Col>
            </Row>
        </Container>

        
    </Layout>
  )
}

const MedServices = ({svg, text}) => {
    return(
        <>
            <div className="mb-3"><MedSVG type={svg} /></div>
            <div>{text}</div>
        </>
    )
}

export const query = graphql`
  query MedQuery {
    honeycomb: file(relativePath: {eq: "images/honeycomb.png"}){
        childImageSharp {
            fluid(maxWidth: 750, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    honeytop: file(relativePath: {eq: "images/honey-nuts.png"}){
        childImageSharp {
            fluid(maxWidth: 512, maxHeight: 512) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    items: allProductsJson (filter: {tag: {eq: "Мед"}}) {
        nodes {
          id
          name
          price
          slug
          slug_category
          shortdesc
          images {
            childImageSharp {
              fluid(maxWidth: 350, maxHeight: 350, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          feature {
            varesi {
              price
            }
          }
        }
      }  
  }
`
