import { navigate } from "gatsby"
import { Link } from "gatsby-plugin-modal-routing"
import React, { useEffect } from "react"
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa"

import {Container, Row, Col} from "react-bootstrap"

export const LayoutModal = ({ children, closeTo, navigation = {} }) => {
  const { current = -1, items = [] } = navigation
  const previous = items[current - 1] ? items[current - 1] : null
  const next = items[current + 1] ? items[current + 1] : null

  const closeModal = () => {
    navigate(closeTo, { state: { noScroll: true } })
  }

  const goPrevious = () => {
    if (!previous) {
      return
    }
    navigate(previous, {
      state: { navigation: { current: current - 1, items }, modal: true },
    })
  }

  const goNext = () => {
    if (!next) {
      return
    }
    navigate(next, {
      state: { navigation: { current: current + 1, items }, modal: true },
    })
  }

  const keyboardNavigation = event => {
    switch (event.keyCode) {
      case 37:
        goPrevious()
        break
      case 39:
        goNext()
        break
      case 27:
        closeModal()
        break
      default:
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", keyboardNavigation)
    return () => {
      window.removeEventListener("keydown", keyboardNavigation)
    }
  })

  return (
    <Container fluid className="position-relative align-self-center h-100 d-flex flex-wrap align-content-center">
      <Container>
        <Row>          
          <Col xs={12} md={10} className="modal-container shadow-lg w-100 px-0 mt-5 mb-2 my-md-3">
            {children}
          </Col>
          <Col xs={6} md={1} className="py-2 order-md-first">
            {previous && (
              <Link
                asModal
                className="d-flex flex-wrap align-content-center justify-content-center w-100 h-100 px-2 text-white no-line"
                state={{ navigation: { current: current - 1, items } }}
                to={previous}
              >
                <FaAngleLeft size="2rem"/>
              </Link>
            )}
          </Col>
          <Col xs={6} md={1} className="py-2">
            {next && (
              <Link
                asModal
                className="d-flex flex-wrap align-content-center justify-content-center w-100 h-100 px-2 text-white no-line"
                state={{ navigation: { current: current + 1, items } }}
                to={next}
              >
                <FaAngleRight size="2rem"/>
              </Link>
            )}
          </Col>

        </Row>
      </Container>
      <button
        className="position-absolute modal-close p-2"
        onClick={closeModal}
      >
        <FaTimes className="text-white" size="1.5rem"/>
      </button>
    </Container>
  )
}
